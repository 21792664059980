<div class="order-status-form">
  <div class="dialog-title">{{ 'ORDERS_PAGE.REVIEW_ORDER_BTN_LABEL' | translate }}</div>

  <div class="rating d-flex justify-content-center">
    <div class="rating-wrapper">
      <div
        class="rating-button"
        [ngClass]="{ 'button-selected': currentRate === 1 }"
        (click)="OnSelectRating(1)"
      >
        <img
          loading="lazy"
          src="../../../../../assets/img/rating-1.svg"
          [alt]="'ORDERS_PAGE.ORDER_RATING_DIALOG.VERY_SAD_RATING' | translate"
        />
      </div>
      <p>{{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.VERY_SAD_RATING' | translate }}</p>
    </div>
    <div class="rating-wrapper">
      <div
        class="rating-button"
        [ngClass]="{ 'button-selected': currentRate === 2 }"
        (click)="OnSelectRating(2)"
      >
        <img
          loading="lazy"
          src="../../../../../assets/img/rating-2.svg"
          [alt]="'ORDERS_PAGE.ORDER_RATING_DIALOG.SAD_RATING' | translate"
        />
      </div>
      <p>{{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.SAD_RATING' | translate }}</p>
    </div>
    <div class="rating-wrapper">
      <div
        class="rating-button"
        [ngClass]="{ 'button-selected': currentRate === 3 }"
        (click)="OnSelectRating(3)"
      >
        <img
          loading="lazy"
          src="../../../../../assets/img/rating-3.svg"
          [alt]="'ORDERS_PAGE.ORDER_RATING_DIALOG.OKAY_RATING' | translate"
        />
      </div>
      <p>{{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.OKAY_RATING' | translate }}</p>
    </div>
    <div class="rating-wrapper">
      <div
        class="rating-button"
        [ngClass]="{ 'button-selected': currentRate === 4 }"
        (click)="OnSelectRating(4)"
      >
        <img
          loading="lazy"
          src="../../../../../assets/img/rating-4.svg"
          [alt]="'ORDERS_PAGE.ORDER_RATING_DIALOG.HAPPY_RATING' | translate"
        />
      </div>
      <p>{{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.HAPPY_RATING' | translate }}</p>
    </div>
    <div class="rating-wrapper">
      <div
        class="rating-button"
        [ngClass]="{ 'button-selected': currentRate === 5 }"
        (click)="OnSelectRating(5)"
      >
        <img
          loading="lazy"
          src="../../../../../assets/img/rating-5.svg"
          [alt]="'ORDERS_PAGE.ORDER_RATING_DIALOG.VERY_HAPPY_RATING' | translate"
        />
      </div>
      <p>{{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.VERY_HAPPY_RATING' | translate }}</p>
    </div>
  </div>

  <div class="negative-rating">
    <select
      [(ngModel)]="complain"
      [ngClass]="{ 'negative-rating-appear': currentRate <= 3 && currentRate > 0 }"
    >
      <option value="" selected disabled hidden>
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.RATING_REASON' | translate }}
      </option>
      <option value="حالة ا">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.PRODUCT_QUALITY_REASON' | translate }}
      </option>
      <option value="حالة ت">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.PRODUCT_PACKAGING_REASON' | translate }}
      </option>
      <option value="خدمة ا">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.SHIPPING_REASON' | translate }}
      </option>
      <option value="سرعة ا">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.SERVICE_SPEED_REASON' | translate }}
      </option>
      <option value="خدمة ا">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.CUSTOMER_SERVICE_REASON' | translate }}
      </option>
      <option value="الاسعا">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.PRICE_REASON' | translate }}
      </option>
      <option value="اخرى">
        {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.OTHER_REASON' | translate }}
      </option>
    </select>
  </div>

  <div
    class="submit-button d-flex justify-content-center"
    [ngClass]="{ 'submit-button-adjust-position': currentRate <= 3 && currentRate > 0 }"
  >
    <button
      (click)="onSubmitRating()"
      [ngClass]="{ 'submit-button-disabled': currentRate === 0 || (currentRate <= 3 && !complain) }"
    >
      {{ 'ORDERS_PAGE.ORDER_RATING_DIALOG.SUBMIT_BTN_LABEL' | translate }}
    </button>
  </div>
</div>
