import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RateOrderModel } from 'src/app/core/domain/order/rate-order.model';
import { RateOrderUseCase } from 'src/app/core/usecases/order/rate-order.usecase';

interface OrderReq {
  currentRate: number;
  order: any;
}
@Component({
  selector: 'app-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss'],
  standalone: true,
  imports: [NgClass, FormsModule, TranslateModule],
})
export class RatingBarComponent {
  public order = this.data.order;

  public complain: string = this.data.order.complain || '';

  public currentRate: number = this.data.currentRate;

  constructor(
    private _rateOrderUseCase: RateOrderUseCase,
    private dialogRef: MatDialogRef<RatingBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderReq,
    private toast: ToastrService,
    private _translateService: TranslateService,
  ) {}

  onSubmitRating() {
    if (this.currentRate > 0) {
      if (this.complain || (!this.complain && this.currentRate > 3)) {
        if (this.currentRate > 3) {
          this.complain = '';
        }
        const rateOrder: RateOrderModel = {
          rating: this.currentRate,
          complain: this.complain,
          orderID: this.order._id,
        };
        this._rateOrderUseCase.execute(rateOrder).subscribe({
          next: () => {
            this.dialogRef.close({ rating: this.currentRate, complain: this.complain });
          },
        });
      } else {
        this.toast.error(this._translateService.instant('ORDERS_PAGE.REASON_FOR_RATING'));
      }
    } else {
      this.toast.error(this._translateService.instant('ORDERS_PAGE.CHOOSE_RATING'));
    }
  }

  OnSelectRating(number: number) {
    this.currentRate = number;
  }
}
